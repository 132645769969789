import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import {
  Grid,
  Typography,
  Icon,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import { textAlignTypes, textVariantTypes, textColorTypes } from '../../propertyValues';
import styles from './style';
import { useRouter, useSmall, useFeatureToggle } from '../../../hooks';
import { sourceTextFromData, isValidHttpUrl } from '../../../utils/functions';
import { domain } from '../../../utils/variables';
import ImageBox from '../imageBox/index';

const useStyles = makeStyles(styles);

const Header = ({
  title,
  seoTitle,
  subTitle,
  seoSubTitle,
  align,
  subTitleAlign,
  titleColor,
  subTitleColor,
  titleVariant,
  subTitleVariant,
  oneLiner,
  cover,
  actions,
  contentLayout,
  actionsLayout,
  noPadding,
  disableSeo,
  titleAppendix,
  onlySeo,
  disableIndex,
}) => {
  const classes = useStyles();
  const isSmall = useSmall();
  const router = useRouter();
  const doFeatureToggle = useFeatureToggle();
  const autoPayFeatureActive = doFeatureToggle(`autoPay`);
  const payAnythingFeatureActive = doFeatureToggle(`payAnything`);
  const makePaymentActive = doFeatureToggle(`makePayment`);

  const storeState = useStore()?.getState() || {};
  storeState.domain = domain;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event, url) => {
    if (url) {
      const newRedirectUrl = sourceTextFromData(storeState, url);
      if (isValidHttpUrl(newRedirectUrl)) {
        window.location.href = newRedirectUrl;
      } else {
        router.push(newRedirectUrl);
      }
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const titleGridSize = contentLayout?.lg || (actions && actions.length > 0 ? 8 : 12);
  const actionsGridSize = actionsLayout?.lg || (actions && actions.length > 0 ? 4 : 12);

  const seoComponent = (
    <Helmet>
      <meta charSet="utf-8" />
      {(domain?.env !== 'production' || disableIndex) && (
        <meta name="robots" content="noindex" />
      )}
      <title>{seoTitle || title}</title>
      <meta name="description" content={seoSubTitle || subTitle} />
    </Helmet>
  );

  if (onlySeo) {
    return seoComponent;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
      sx={{ px: isSmall && !noPadding ? 1 : 0, py: isSmall && !noPadding ? 1 : 0 }}
    >
      {!disableSeo && seoComponent}
      <Grid
        item
        xs={contentLayout?.xs || contentLayout?.sm || 12}
        sm={contentLayout?.sm || 12}
        lg={titleGridSize}
      >
        {cover && (
          <ImageBox
            source={{
              sm: { url: cover.url },
              md: { url: cover.url },
              lg: { url: cover.url },
            }}
          />
        )}
        {title && (
          <>
            <Typography
              variant={titleVariant}
              align={align}
              className={classes.header}
              color={titleColor}
            >
              {sourceTextFromData(storeState, title)}{' '}
              {titleAppendix && (
                <span className={classes.titleAppendix}>
                  {sourceTextFromData(storeState, titleAppendix)}
                </span>
              )}
            </Typography>
          </>
        )}
        {subTitle && (
          <Typography
            variant={subTitleVariant}
            align={subTitleAlign || align}
            color={subTitleColor}
            className={classnames(
              { [classes.oneLineSubHeader]: oneLiner },
              { [classes.subHeader]: !oneLiner },
            )}
          >
            {sourceTextFromData(storeState, subTitle)}
          </Typography>
        )}
      </Grid>
      {actions && actions.length > 0 && (
        <Grid
          item
          xs={actionsLayout?.xs || actionsLayout?.sm || 12}
          sm={actionsLayout?.sm || 12}
          lg={actionsGridSize}
        >
          <Grid
            container
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {actions.map(action => {
              if (action?.hide) return null;
              if (action?.title?.includes('Recurring') && !autoPayFeatureActive)
                return null;
              if (action?.title?.includes('Custom Pay') && !payAnythingFeatureActive)
                return null;
              if (action?.title?.includes('Payment Option') && !makePaymentActive)
                return null;
              let contentButton = null;
              if (action?.type === 'menu') {
                contentButton = (
                  <>
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? 'composition-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      className={classes[action?.variant || 'primaryButton']}
                      size={action?.size || 'small'}
                      fullWidth={action?.fullWidth || false}
                      width={action?.width || 'auto'}
                      startIcon={
                        action?.icon && (
                          <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                            {action?.icon}
                          </Icon>
                        )
                      }
                      endIcon={
                        action?.endIcon && (
                          <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                            {action?.endIcon}
                          </Icon>
                        )
                      }
                      sx={{
                        width: 'auto',
                        p: isSmall ? '0.4rem 0.8rem' : '.5rem 1.25rem',
                        mx: 0.5,
                        textAlign: action?.textAlign || 'left',
                      }}
                    >
                      {action?.title}
                    </Button>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-end"
                      transition
                      disablePortal
                      sx={{ zIndex: 100 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                              >
                                {action?.menuOptions?.map(x => {
                                  if (
                                    x?.title?.includes('Recurring') &&
                                    !autoPayFeatureActive
                                  )
                                    return null;
                                  if (
                                    x?.title?.includes('Custom Pay') &&
                                    !payAnythingFeatureActive
                                  )
                                    return null;
                                  if (
                                    x?.title?.includes('Payment Option') &&
                                    !makePaymentActive
                                  )
                                    return null;
                                  return (
                                    <MenuItem
                                      onClick={e => {
                                        if (x?.onClick) {
                                          x.onClick(e);
                                        }
                                        handleClose(e, x?.link);
                                      }}
                                    >
                                      {x?.title}
                                    </MenuItem>
                                  );
                                })}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                );
              } else
                contentButton = (
                  <Button
                    className={classes[action?.variant || 'primaryButton']}
                    size={action?.size || 'small'}
                    fullWidth={action?.fullWidth || false}
                    width={action?.width || 'auto'}
                    onClick={action?.onClick}
                    component={RouterLink}
                    to={action?.link}
                    target={action?.target}
                    startIcon={
                      action?.icon && (
                        <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                          {action?.icon}
                        </Icon>
                      )
                    }
                    endIcon={
                      action?.endIcon && (
                        <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                          {action?.endIcon}
                        </Icon>
                      )
                    }
                    sx={{
                      textAlign: action?.textAlign || 'left',
                    }}
                    disabled={action?.isDisabled || false}
                  >
                    {action?.title}
                  </Button>
                );

              return (
                <Grid
                  item
                  xs={action?.xs || 'auto'}
                  md={action?.md || 'auto'}
                  lg={action?.lg}
                >
                  {contentButton}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;

Header.propTypes = {
  align: PropTypes.oneOf(textAlignTypes),
  title: PropTypes.string,
  titleColor: PropTypes.oneOf(textColorTypes),
  titleVariant: PropTypes.oneOf(textVariantTypes),
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.oneOf(textColorTypes),
  subTitleVariant: PropTypes.oneOf(textVariantTypes),
  oneLiner: PropTypes.bool,
};

Header.defaultProps = {
  align: 'left',
  subTitle: '',
  title: '',
  titleColor: 'text.main',
  subTitleColor: 'text.dark',
  titleVariant: 'h6',
  subTitleVariant: 'subtitle1',
  oneLiner: false,
};

/*
className={classnames(
              { [classes.oneLineSubHeader]: oneLiner },
              { [classes.subHeader]: !oneLiner },
            )}
            */
