import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ProductsList from '../../list/productsList';
import DutchProductsList from '../../list/productsList/dutchProductsList';
import routes from '../../../utils/variables/routes';
import { useFeatureToggle, useVariables } from '../../../hooks';
import { getRef } from '../../../utils/functions';
import { addWizard } from '../../../actions/wizards';
import { getAccessToken } from '../../../storage/accessToken';
import { agentType, dutchTemplate } from '../../../utils/variables';

const getQuickActions = ({
  variables,
  doFeatureToggle,
  dispatch,
  isAuthenticated,
  theme,
}) => {
  const isAgent = useSelector(state => state?.auth?.usertype === agentType);
  let actions = variables?.quickActions || [];

  if (isAgent && actions.length > 0) {
    actions = actions.map(action => {
      if (action.title === 'Promotion') {
        return { ...action, active: false };
      }
      if (action.title === 'Refer a Customer') {
        return { ...action, active: true };
      }
      return action;
    });
  }
  const makePaymentFeatureActive = doFeatureToggle(`makePayment`);
  const filteredActions = actions.filter(x => {
    let active = x?.active;
    if (x?.hide && x?.hide === 'auth' && active) {
      active = !isAuthenticated;
    }

    return active;
  });
  return filteredActions.map(x => ({
    ...x,
    href:
      x?.href === '/make-payment' && !makePaymentFeatureActive
        ? routes.portfolio.portfolioSummary
        : x?.href,
    background: getRef(theme?.palette, x?.background),
    iconBackground: getRef(theme?.palette, x?.iconBackground),
    textColor: getRef(theme?.palette, x?.textColor),
    subTitleColor: getRef(theme?.palette, x?.subTitleColor),
  }));
};

function QuickActionBar() {
  const doFeatureToggle = useFeatureToggle();
  const dispatch = useDispatch();
  const variables = useVariables();
  const theme = useTheme();

  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);

  return theme.template === dutchTemplate ? (
    <DutchProductsList
      products={getQuickActions({
        variables,
        doFeatureToggle,
        dispatch,
        isAuthenticated,
        theme,
      })}
      isAction
      mobileColumnWidth={4}
    />
  ) : (
    <ProductsList
      products={getQuickActions({
        variables,
        doFeatureToggle,
        dispatch,
        isAuthenticated,
        theme,
      })}
      isAction
      mobileColumnWidth={4}
    />
  );
}

export default QuickActionBar;
