import {
  SIGNUP_BIO_REQUEST,
  SIGNUP_BIO_ERROR,
  SIGNUP_BIO_SUCCESS,
  SIGNUP_CONTACT_REQUEST,
  SIGNUP_CONTACT_ERROR,
  SIGNUP_CONTACT_SUCCESS,
  SIGNUP_EXISTING_CUSTOMER_REQUEST,
  SIGNUP_EXISTING_CUSTOMER_ERROR,
  SIGNUP_EXISTING_CUSTOMER_SUCCESS,
  CONFIRM_INFO_API_REQUEST,
  CONFIRM_INFO_API_ERROR,
  CONFIRM_INFO_API_SUCCESS,
  VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  VERIFY_IDENTITY_CONFIRM_API_ERROR,
  VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR,
  SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  FORGOT_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  FORGOT_VERIFY_IDENTITY_CONFIRM_API_ERROR,
  FORGOT_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  VERIFY_IDENTITY_SEND_API_REQUEST,
  VERIFY_IDENTITY_SEND_API_ERROR,
  VERIFY_IDENTITY_SEND_API_SUCCESS,
  VERIFY_IDENTITY_SEND_SMS_API_REQUEST,
  VERIFY_IDENTITY_SEND_SMS_API_ERROR,
  VERIFY_IDENTITY_SEND_SMS_API_SUCCESS,
  VALIDATE_CREDENTIALS_API_REQUEST,
  VALIDATE_CREDENTIALS_API_SUCCESS,
  VALIDATE_CREDENTIALS_API_ERROR,
  SIGNIN_API_REQUEST,
  SIGNIN_API_ERROR,
  SIGNIN_API_SUCCESS,
  FORGOT_PASSWORD_API_REQUEST,
  FORGOT_PASSWORD_API_ERROR,
  FORGOT_PASSWORD_API_SUCCESS,
  AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR,
  AGENT_FORGOT_PASSWORD_API_REQUEST,
  AGENT_FORGOT_PASSWORD_API_ERROR,
  AGENT_FORGOT_PASSWORD_API_SUCCESS,
  RESET_PASSWORD_API_REQUEST,
  RESET_PASSWORD_API_ERROR,
  RESET_PASSWORD_API_SUCCESS,
  AGENT_RESET_PASSWORD_API_REQUEST,
  AGENT_RESET_PASSWORD_API_ERROR,
  AGENT_RESET_PASSWORD_API_SUCCESS,
  SET_PASSWORD_API_REQUEST,
  SET_PASSWORD_API_ERROR,
  SET_PASSWORD_API_SUCCESS,
  RESET_AUTH_SUCCESS,
  SIGNIN_VIA_FACEBOOK_API_SUCCESS,
  SIGNIN_VIA_FACEBOOK_API_ERROR,
  SIGNIN_VIA_FACEBOOK_API_REQUEST,
  SIGN_OUT_API_REQUEST,
  SIGN_OUT_API_ERROR,
  SIGN_OUT_API_SUCCESS,
  FACEBOOK_REDIRECT_API_SUCCESS,
  FACEBOOK_REDIRECT_API_ERROR,
  FACEBOOK_REDIRECT_API_REQUEST,
  FORGOT_VERIFY_IDENTITY_SEND_API_REQUEST,
  FORGOT_VERIFY_IDENTITY_SEND_API_ERROR,
  FORGOT_VERIFY_IDENTITY_SEND_API_SUCCESS,
  SIGNIN_VIA_GOOGLE_API_SUCCESS,
  SIGNIN_VIA_GOOGLE_API_ERROR,
  SIGNIN_VIA_GOOGLE_API_REQUEST,
  GOOGLE_REDIRECT_API_SUCCESS,
  GOOGLE_REDIRECT_API_ERROR,
  GOOGLE_REDIRECT_API_REQUEST,
  GET_NEW_ACCESS_TOKEN_API_REQUEST,
  GET_NEW_ACCESS_TOKEN_API_ERROR,
  GET_NEW_ACCESS_TOKEN_API_SUCCESS,
  CHANGE_CUSTOMER_ID_API_SUCCESS,
  SELECT_CLIENTS_SUCCESS,
  SELECT_PRODUCTS_SUCCESS,
  UPDATE_SOURCE_ID_SUCCESS,
  ADD_TRUSTED_DEVICE_API_REQUEST,
  ADD_TRUSTED_DEVICE_API_ERROR,
  ADD_TRUSTED_DEVICE_API_SUCCESS,
  SET_AUTH_USERNAME_SUCCESS,
  RESET_AUTH_STATE_SUCCESS,
  SET_RECAPTCHA_TOKEN,
  TRIGGER_RECAPTCHA,
  GET_FATUM_SESSION_ID_API_REQUEST,
  GET_FATUM_SESSION_ID_API_ERROR,
  GET_FATUM_SESSION_ID_API_SUCCESS,
} from '../../constants/authActionNames';

import { RESET_UNAUTH_CHANGE_PASSWORD_SUCCESS } from '../../constants/account/unAuthChangePasswordActionNames';

import { VERIFY_CONTACT_API_SUCCESS } from '../../constants/profileActionNames';

import { CONFIRM_POLICY_API_SUCCESS } from '../../constants/account/confirmPolicyActionNames';

import { RESET_EXPIRED_SUCCESS } from '../../constants/miscActionNames';

import { createPayload } from '../profile';
import {
  stringToDate,
  isoStringToDate,
  dateToString,
  isoStringToDob,
  addItemOnce,
  getAgeFromDate,
  formatTitleToDropDownOptions,
  removeSessionData,
} from '../../utils/functions';
import {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
} from '../../storage/accessToken';
import { customerType, persistKey, agentType } from '../../utils/variables';

// // console.log('encryptKey: ', encryptKey);

// 5000041453 temp policy id
export const initialState = {
  encryptKey: null,
  isExpired: false,
  expiresIn: null,
  isAuthenticated: false,
  revalidate: false,
  user: null, // { id: '002109', accessToken: null, customer: { id: '000425' } },
  signInResponse: null,
  isSignInPending: false,
  isSignInError: false,
  isSignInSuccess: false,
  signInError: null,
  signUpBioResponse: null,
  isSignUpBioPending: false,
  isSignUpBioError: false,
  isSignUpBioSuccess: false,
  signUpBioError: null,
  signUpExistingCustomerResponse: null,
  isSignUpExistingCustomerPending: false,
  isSignUpExistingCustomerError: false,
  isSignUpExistingCustomerSuccess: false,
  signUpExistingCustomerError: null,
  signUpContactResponse: null,
  isSignUpContactPending: false,
  isSignUpContactError: false,
  isSignUpContactSuccess: false,
  signUpContactError: null,
  confirmInfoResponse: null,
  isConfirmInfoPending: false,
  isConfirmInfoError: false,
  isConfirmInfoSuccess: false,
  confirmInfoError: null,
  usertype: customerType,
  username: null,
  selectedClient: null,
  selectedProduct: null,
  isValidateCredentialsPending: false,
  isValidateCredentialsSuccess: false,
  isValidateCredentialsError: false,
  validateCredentialsResponse: null,
  validateCredentialsError: null,
  verifyIdentityConfirmResponse: null,
  isVerifyIdentityConfirmPending: false,
  isVerifyIdentityConfirmError: false,
  isVerifyIdentityConfirmSuccess: false,
  verifyIdentityConfirmError: null,

  signUpVerifyIdentityConfirmResponse: null,
  isSignUpVerifyIdentityConfirmPending: false,
  isSignUpVerifyIdentityConfirmError: false,
  isSignUpVerifyIdentityConfirmSuccess: false,
  signUpVerifyIdentityConfirmError: null,

  agentSignUpVerifyIdentityConfirmResponse: null,
  isAgentSignUpVerifyIdentityConfirmPending: false,
  isAgentSignUpVerifyIdentityConfirmError: false,
  isAgentSignUpVerifyIdentityConfirmSuccess: false,
  agentSignUpVerifyIdentityConfirmError: null,

  forgotVerifyIdentityConfirmResponse: null,
  isForgotVerifyIdentityConfirmPending: false,
  isForgotVerifyIdentityConfirmError: false,
  isForgotVerifyIdentityConfirmSuccess: false,
  forgotVerifyIdentityConfirmError: null,

  verifyIdentitySendResponse: null,
  isVerifyIdentitySendPending: false,
  isVerifyIdentitySendError: false,
  isVerifyIdentitySendSuccess: false,
  verifyIdentitySendError: null,

  verifyIdentitySendSmSResponse: null,
  isVerifyIdentitySendSmSPending: false,
  isVerifyIdentitySendSmSError: false,
  isVerifyIdentitySendSmSSuccess: false,
  verifyIdentitySendSmSError: null,

  forgotVerifyIdentitySendResponse: null,
  isForgotVerifyIdentitySendPending: false,
  isForgotVerifyIdentitySendError: false,
  isForgotVerifyIdentitySendSuccess: false,
  forgotVerifyIdentitySendError: null,

  forgotPasswordResponse: null,
  isForgotPasswordPending: false,
  isForgotPasswordError: false,
  isForgotPasswordSuccess: false,
  forgotPasswordError: null,

  resetPasswordResponse: null,
  isResetPasswordPending: false,
  isResetPasswordError: false,
  isResetPasswordSuccess: false,
  resetPasswordError: null,

  agentForgotPasswordResponse: null,
  isAgentForgotPasswordPending: false,
  isAgentForgotPasswordError: false,
  isAgentForgotPasswordSuccess: false,
  agentForgotPasswordError: null,

  agentResetPasswordResponse: null,
  isAgentResetPasswordPending: false,
  isAgentResetPasswordError: false,
  isAgentResetPasswordSuccess: false,
  agentResetPasswordError: null,

  setPasswordResponse: null,
  isSetPasswordPending: false,
  isSetPasswordError: false,
  isSetPasswordSuccess: false,
  setPasswordError: null,

  signInViaFacebookResponse: null,
  isSignInViaFacebookPending: false,
  isSignInViaFacebookError: false,
  isSignInViaFacebookSuccess: false,
  signInViaFacebookError: null,

  signOutResponse: null,
  isSignOutPending: false,
  isSignOutError: false,
  isSignOutSuccess: false,
  signOutError: null,
  facebookRedirectResponse: null,
  isFacebookRedirectPending: false,
  isFacebookRedirectError: false,
  isFacebookRedirectSuccess: false,
  facebookRedirectError: null,

  signInViaGoogleResponse: null,
  isSignInViaGooglePending: false,
  isSignInViaGoogleError: false,
  isSignInViaGoogleSuccess: false,
  signInViaGoogleError: null,
  googleRedirectResponse: null,
  isGoogleRedirectPending: false,
  isGoogleRedirectError: false,
  isGoogleRedirectSuccess: false,
  googleRedirectError: null,

  getNewAccessTokenResponse: null,
  isGetNewAccessTokenPending: false,
  isGetNewAccessTokenError: false,
  isGetNewAccessTokenSuccess: false,
  getNewAccessTokenError: null,

  isAddTrustedDevicePending: false,
  isAddTrustedDeviceError: false,
  isAddTrustedDeviceSuccess: false,
  addTrustedDeviceResponse: null,
  signOutType: null,
  recaptchaToken: null,
  triggerRecaptcha: null,
  isFatumSessionIdPending: false,
  isFatumSessionIdSuccess: false,
  isFatumSessionIdError: false,
  fatumSessionResponse: null,
  fatumSessionError: null,
  fatumSessionId: null,
  setAuthOptions: null,
};
// VERY IMPORTANT
// each action resets the state in the reducer so make sure to pass key here is you dont want it resetted

function authReducer(_state = initialState, action) {
  // IMPORTANT add keys here if you dont want it erased on each request
  const nowTime = new Date().getTime();
  const resetState = {
    ...initialState,
    signOutType: _state.signOutType,
    isAuthenticated: _state.isAuthenticated,
    [persistKey]: _state[persistKey],
    revalidate: _state.revalidate,
    usertype: _state.usertype,
    username: _state.username,
    usersubtype: _state.usersubtype,
    selectedClient: _state.selectedClient,
    selectedProduct: _state.selectedProduct,
    encryptKey: _state.encryptKey || initialState?.encryptKey,
    user: _state.user,
    isExpired: _state.isExpired,
    expiresIn: _state.expiresIn,
    signInResponse: _state.signInResponse,
    signUpBioResponse: _state.signUpBioResponse,
    signUpExistingCustomerResponse: _state.signUpExistingCustomerResponse,
    signUpContactResponse: _state.signUpContactResponse,
    confirmInfoResponse: _state.confirmInfoResponse,
    verifyIdentitySendSmSResponse: _state.verifyIdentitySendSmSResponse,
    confirmProductResponse: _state.confirmProductResponse,
    verifyIdentityConfirmResponse: _state.verifyIdentityConfirmResponse,
    signUpVerifyIdentityConfirmResponse: _state.signUpVerifyIdentityConfirmResponse,
    forgotVerifyIdentityConfirmResponse: _state.forgotVerifyIdentityConfirmResponse,
    forgotPasswordResponse: _state.forgotPasswordResponse,
    resetPasswordResponse: _state.resetPasswordResponse,
    setPasswordResponse: _state.setPasswordResponse,
    signInViaFacebookResponse: _state.signInViaFacebookResponse,
    verifyIdentitySendResponse: _state.verifyIdentitySendResponse,

    validateCredentialsResponse: _state.validateCredentialsResponse,
    signOutResponse: _state.signOutResponse,
    facebookRedirectResponse: _state.facebookRedirectResponse,
    forgotVerifyIdentitySendResponse: _state.forgotVerifyIdentitySendResponse,
    signInViaGoogleResponse: _state.signInViaGoogleResponse,
    googleRedirectResponse: _state.googleRedirectResponse,

    getNewAccessTokenResponse: _state.getNewAccessTokenResponse,
    agentForgotPasswordResponse: _state.agentForgotPasswordResponse,
    agentResetPasswordResponse: _state.agentResetPasswordResponse,

    addTrustedDeviceResponse: _state.addTrustedDeviceResponse,
    recaptchaToken: _state.recaptchaToken,
    triggerRecaptcha: _state.triggerRecaptcha,
    fatumSessionResponse: _state.fatumSessionResponse,
    fatumSessionId: _state.fatumSessionId,
    setAuthOptions: _state.setAuthOptions,
  };
  // IMPORTANT add keys above here if you dont want it erased on each request

  const state = { ...resetState };

  switch (action.type) {
    case GET_NEW_ACCESS_TOKEN_API_REQUEST:
      return {
        ...state,
        isGetNewAccessTokenPending: true,
        isGetNewAccessTokenSuccess: false,
        isGetNewAccessTokenError: false,
      };
    case GET_NEW_ACCESS_TOKEN_API_SUCCESS: {
      const {
        accessToken,
        expiresIn,
        refreshedToken,
        clientExpiresIn,
      } = action?.payload?.payload;
      const { user } = state;
      if (user) {
        user.logInTime = new Date().getTime();
        user.accessToken = accessToken || refreshedToken;
        setAccessToken(accessToken);
        user.expiresIn = expiresIn || user?.expiresIn;
        user.clientExpiresIn =
          clientExpiresIn || user?.clientExpiresIn || user?.expiresIn / 2;
        // user.expiresIn = 30;
      }

      return {
        ...state,
        isGetNewAccessTokenPending: false,
        isGetNewAccessTokenSuccess: true,
        isGetNewAccessTokenError: false,
        getNewAccessTokenResponse: action.payload,
        revalidate: false,
        user,
        encryptKey: user?.accessToken,
      };
    }
    case GET_NEW_ACCESS_TOKEN_API_ERROR:
      return {
        ...state,
        isGetNewAccessTokenPending: false,
        isGetNewAccessTokenSuccess: false,
        isGetNewAccessTokenError: true,
        revalidate: false,
        getNewAccessTokenError: action.payload,
      };

    case SIGNUP_BIO_REQUEST:
      return {
        ...state,
        isSignUpBioPending: true,
        isSignUpBioSuccess: false,
        isSignUpBioError: false,
        [persistKey]: nowTime,
      };
    case SIGNUP_BIO_SUCCESS: {
      return {
        ...state,
        isSignUpBioPending: false,
        isSignUpBioSuccess: true,
        isSignUpBioError: false,
        signUpBioResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case SIGNUP_BIO_ERROR:
      return {
        ...state,
        isSignUpBioPending: false,
        isSignUpBioSuccess: false,
        isSignUpBioError: true,
        [persistKey]: nowTime,
        // signUpBioError: action.payload,
      };

    case SIGNUP_EXISTING_CUSTOMER_REQUEST:
      return {
        ...state,
        isSignUpExistingCustomerPending: true,
        isSignUpExistingCustomerSuccess: false,
        isSignUpExistingCustomerError: false,
        [persistKey]: nowTime,
      };
    case SIGNUP_EXISTING_CUSTOMER_SUCCESS: {
      return {
        ...state,
        isSignUpExistingCustomerPending: false,
        isSignUpExistingCustomerSuccess: true,
        isSignUpExistingCustomerError: false,
        signUpExistingCustomerResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case SIGNUP_EXISTING_CUSTOMER_ERROR:
      return {
        ...state,
        isSignUpExistingCustomerPending: false,
        isSignUpExistingCustomerSuccess: false,
        isSignUpExistingCustomerError: true,
        [persistKey]: nowTime,
        // signUpBioError: action.payload,
      };

    case SIGNUP_CONTACT_REQUEST:
      return {
        ...state,
        isSignUpContactPending: true,
        isSignUpContactSuccess: false,
        isSignUpContactError: false,
        [persistKey]: nowTime,
      };

    case SIGNUP_CONTACT_SUCCESS: {
      return {
        ...state,
        isSignUpContactPending: false,
        isSignUpContactSuccess: true,
        isSignUpContactError: false,
        signUpContactResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case SIGNUP_CONTACT_ERROR: {
      return {
        ...state,
        isSignUpContactPending: false,
        isSignUpContactSuccess: false,
        isSignUpContactError: true,
        // signUpContactError: action.payload,
        [persistKey]: nowTime,
      };
    }
    case CONFIRM_INFO_API_REQUEST:
      return {
        ...state,
        isConfirmInfoPending: true,
        isConfirmInfoSuccess: false,
        isConfirmInfoError: false,
        [persistKey]: nowTime,
      };
    case CONFIRM_INFO_API_SUCCESS: {
      const result = state?.signUpContactResponse;

      const passwordKey = 'password';
      const confirmPasswordKey = 'confirmPassword';

      if (result && typeof result === 'object') {
        delete result[passwordKey];
        delete result[confirmPasswordKey];
      }
      return {
        ...state,
        signUpContactResponse: result,
        isConfirmInfoPending: false,
        isConfirmInfoSuccess: true,
        isConfirmInfoError: false,
        confirmInfoResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case CONFIRM_INFO_API_ERROR: {
      return {
        ...state,
        isConfirmInfoPending: false,
        isConfirmInfoSuccess: false,
        isConfirmInfoError: true,
        // confirmInfoError: action.payload,
        [persistKey]: nowTime,
      };
    }

    case VERIFY_IDENTITY_SEND_API_REQUEST:
      return {
        ...state,
        isVerifyIdentitySendPending: true,
        isVerifyIdentitySendSuccess: false,
        isVerifyIdentitySendError: false,
        [persistKey]: nowTime,
      };
    case VERIFY_IDENTITY_SEND_API_SUCCESS:
      return {
        ...state,
        isVerifyIdentitySendPending: false,
        isVerifyIdentitySendSuccess: true,
        isVerifyIdentitySendError: false,
        verifyIdentitySendResponse: action.payload,
        facebookRedirectResponse: {
          ...state.facebookRedirectResponse,
          ...action.payload,
        },
        googleRedirectResponse: { ...state.googleRedirectResponse, ...action.payload },
        validateCredentialsResponse: {
          ...state.validateCredentialsResponse,
          ...action.payload,
        },
        [persistKey]: nowTime,
      };
    case VERIFY_IDENTITY_SEND_API_ERROR:
      return {
        ...state,
        isVerifyIdentitySendPending: false,
        isVerifyIdentitySendSuccess: false,
        isVerifyIdentitySendError: true,
        [persistKey]: nowTime,
        // verifyIdentitySendError: action.payload,
        // facebookRedirectError: { ...state.facebookRedirectError, ...action.payload },
        // googleRedirectError: { ...state.googleRedirectError, ...action.payload },
        // validateCredentialsError: {
        //  ...state.validateCredentialsError,
        //  ...action.payload,
        // },
      };

    case VERIFY_IDENTITY_SEND_SMS_API_REQUEST:
      return {
        ...state,
        isVerifyIdentitySendSmSPending: true,
        isVerifyIdentitySendSmSSuccess: false,
        isVerifyIdentitySendSmSError: false,
        [persistKey]: nowTime,
      };
    case VERIFY_IDENTITY_SEND_SMS_API_SUCCESS:
      return {
        ...state,
        isVerifyIdentitySendSmSPending: false,
        isVerifyIdentitySendSmSSuccess: true,
        isVerifyIdentitySendSmSError: false,
        verifyIdentitySendSmSResponse: action.payload,
        confirmInfoResponse: { ...state.confirmInfoResponse, ...action.payload },
        [persistKey]: nowTime,
      };
    case VERIFY_IDENTITY_SEND_SMS_API_ERROR:
      return {
        ...state,
        isVerifyIdentitySendSmSPending: false,
        isVerifyIdentitySendSmSSuccess: false,
        isVerifyIdentitySendSmSError: true,
        [persistKey]: nowTime,
        // verifyIdentitySendSmSError: action.payload,
        // confirmInfoError: { ...state.confirmInfoError, ...action.payload },
      };

    case VALIDATE_CREDENTIALS_API_REQUEST:
      return {
        ...state,
        isValidateCredentialsPending: true,
        isValidateCredentialsSuccess: false,
        isValidateCredentialsError: false,
        [persistKey]: nowTime,
      };
    case VALIDATE_CREDENTIALS_API_SUCCESS:
      return {
        ...state,
        isValidateCredentialsPending: false,
        isValidateCredentialsSuccess: true,
        isValidateCredentialsError: false,
        validateCredentialsResponse: action.payload,
        usertype: state.usertype || customerType,
        [persistKey]: nowTime,
      };
    case VALIDATE_CREDENTIALS_API_ERROR:
      return {
        ...state,
        isValidateCredentialsPending: false,
        isValidateCredentialsSuccess: false,
        isValidateCredentialsError: true,
        validateCredentialsError: action.payload,
        [persistKey]: nowTime,
      };

    case VERIFY_IDENTITY_CONFIRM_API_REQUEST:
      return {
        ...state,
        isVerifyIdentityConfirmPending: true,
        isVerifyIdentityConfirmSuccess: false,
        isVerifyIdentityConfirmError: false,
        [persistKey]: nowTime,
      };
    case VERIFY_IDENTITY_CONFIRM_API_SUCCESS:
      return {
        ...state,
        isVerifyIdentityConfirmPending: false,
        isVerifyIdentityConfirmSuccess: true,
        isVerifyIdentityConfirmError: false,
        verifyIdentityConfirmResponse: action.payload,
        [persistKey]: nowTime,
      };

    case VERIFY_IDENTITY_CONFIRM_API_ERROR:
      return {
        ...state,
        isVerifyIdentityConfirmPending: false,
        isVerifyIdentityConfirmSuccess: false,
        isVerifyIdentityConfirmError: true,
        verifyIdentityConfirmError: action.payload,
        [persistKey]: nowTime,
      };

    case SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST:
      return {
        ...state,
        isSignUpVerifyIdentityConfirmPending: true,
        isSignUpVerifyIdentityConfirmSuccess: false,
        isSignUpVerifyIdentityConfirmError: false,
        [persistKey]: nowTime,
      };
    case SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS:
      return {
        ...state,
        isSignUpVerifyIdentityConfirmPending: false,
        isSignUpVerifyIdentityConfirmSuccess: true,
        isSignUpVerifyIdentityConfirmError: false,
        signUpVerifyIdentityConfirmResponse: action.payload,
        [persistKey]: nowTime,
      };
    case SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR:
      return {
        ...state,
        isSignUpVerifyIdentityConfirmPending: false,
        isSignUpVerifyIdentityConfirmSuccess: false,
        isSignUpVerifyIdentityConfirmError: true,
        signUpVerifyIdentityConfirmError: action.payload,
        [persistKey]: nowTime,
      };
    case AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST:
      return {
        ...state,
        isAgentSignUpVerifyIdentityConfirmPending: true,
        isAgentSignUpVerifyIdentityConfirmSuccess: false,
        isAgentSignUpVerifyIdentityConfirmError: false,
        [persistKey]: nowTime,
      };
    case AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS:
      return {
        ...state,
        isAgentSignUpVerifyIdentityConfirmPending: false,
        isAgentSignUpVerifyIdentityConfirmSuccess: true,
        isAgentSignUpVerifyIdentityConfirmError: false,
        agentSignUpVerifyIdentityConfirmResponse: action.payload,
        [persistKey]: nowTime,
      };
    case AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR:
      return {
        ...state,
        isAgentSignUpVerifyIdentityConfirmPending: false,
        isAgentSignUpVerifyIdentityConfirmSuccess: false,
        isAgentSignUpVerifyIdentityConfirmError: true,
        agentSignUpVerifyIdentityConfirmError: action.payload,
        [persistKey]: nowTime,
      };

    case FORGOT_VERIFY_IDENTITY_CONFIRM_API_REQUEST:
      return {
        ...state,
        isForgotVerifyIdentityConfirmPending: true,
        isForgotVerifyIdentityConfirmSuccess: false,
        isForgotVerifyIdentityConfirmError: false,
        [persistKey]: nowTime,
      };
    case FORGOT_VERIFY_IDENTITY_CONFIRM_API_SUCCESS:
      return {
        ...state,
        isForgotVerifyIdentityConfirmPending: false,
        isForgotVerifyIdentityConfirmSuccess: true,
        isForgotVerifyIdentityConfirmError: false,
        forgotVerifyIdentityConfirmResponse: action.payload,
        [persistKey]: nowTime,
      };
    case FORGOT_VERIFY_IDENTITY_CONFIRM_API_ERROR:
      return {
        ...state,
        isForgotVerifyIdentityConfirmPending: false,
        isForgotVerifyIdentityConfirmSuccess: false,
        isForgotVerifyIdentityConfirmError: true,
        forgotVerifyIdentityConfirmError: action.payload,
        [persistKey]: nowTime,
      };

    case SIGNIN_API_REQUEST:
      return {
        ...state,
        isSignInPending: true,
        isSignInSuccess: false,
        isSignInError: false,
      };
    case SIGNIN_API_SUCCESS: {
      const user = { ...action?.payload?.payload } || null; // ...state?.user,
      // user.idVerification.canVerify = false;
      if (user.idVerification) {
        if (
          user?.idVerification?.canVerify === false ||
          user?.idVerification?.retryAttemptsExceeded === true
        ) {
          user.idVerification.continueApp = 'false';
        } else {
          user.idVerification.continueApp = 'true';
        }
      }

      user.dob = dateToString(isoStringToDob(user?.dob));
      user.title = formatTitleToDropDownOptions(user.title);
      const initialCustomer = user?.customerDetails?.customer || {};
      const newCustomer = createPayload(initialCustomer);
      newCustomer.dob = dateToString(isoStringToDob(newCustomer?.dob));
      newCustomer.age =
        getAgeFromDate(dateToString(isoStringToDob(newCustomer?.dob))) ||
        getAgeFromDate(dateToString(isoStringToDob(user?.dob)));
      // // console.log('newCustomerDetails: ', newCustomerDetails);

      // user.sourceIds = getSourceIdsPayload(newCustomer);
      user.customerDetails = { customer: newCustomer };

      // user.expiresIn = 20;
      // console.log('user.expiresIn: ', user.expiresIn);
      user.clientExpiresIn = user?.clientExpiresIn || user?.expiresIn / 2;
      // user.expiresIn = 30;
      // user.customer.id = user?.customer?.id || initialState?.user?.customer?.id;
      const isAuthenticated = !!(user && user.id && user.accessToken);
      const accessToken = user?.accessToken;

      user.logInTime = new Date().getTime();
      setAccessToken(accessToken);
      const finalType = user?.agent ? agentType : customerType;

      if (
        Array.isArray(user?.agent?.webstarDetails) &&
        user?.agent?.webstarDetails?.length > 0
      ) {
        user.agent.webstarDetails = user?.agent?.webstarDetails?.map(item => ({
          ...item,
          combineWebstarDetails: `${item?.country}  ${item?.webstarProducerNumber ||
            item?.producerNumber}/${item?.webstarConsumerNumber ||
            item?.commissionNumber}`,
        }));
      }

      removeSessionData();
      return {
        ...state,
        isSignInPending: false,
        isSignInSuccess: true,
        isSignInError: false,
        signInResponse: action.payload,
        user,
        encryptKey: accessToken,
        usertype: finalType,
        isAuthenticated,
        revalidate: false,
        isExpired: false,
        expiresIn: user?.expiresIn,
        [persistKey]: nowTime,
      };
    }
    case SIGNIN_API_ERROR:
      return {
        ...state,
        isSignInPending: false,
        isSignInSuccess: false,
        isSignInError: true,
        signInError: action.payload,
        [persistKey]: nowTime,
      };

    case FACEBOOK_REDIRECT_API_REQUEST:
      return {
        ...state,
        isFacebookRedirectPending: true,
        isFacebookRedirectSuccess: false,
        isFacebookRedirectError: false,
        [persistKey]: nowTime,
      };
    case FACEBOOK_REDIRECT_API_SUCCESS: {
      return {
        ...state,
        isFacebookRedirectPending: false,
        isFacebookRedirectSuccess: true,
        isFacebookRedirectError: false,
        facebookRedirectResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case FACEBOOK_REDIRECT_API_ERROR:
      return {
        ...state,
        isFacebookRedirectPending: false,
        isFacebookRedirectSuccess: false,
        isFacebookRedirectError: true,
        facebookRedirectError: action.payload,
        [persistKey]: nowTime,
      };

    case FORGOT_PASSWORD_API_REQUEST:
      return {
        ...state,
        isForgotPasswordPending: true,
        isForgotPasswordSuccess: false,
        isForgotPasswordError: false,
        [persistKey]: nowTime,
      };
    case FORGOT_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isForgotPasswordPending: false,
        isForgotPasswordSuccess: true,
        isForgotPasswordError: false,
        forgotPasswordResponse: action.payload,
        [persistKey]: nowTime,
      };
    case FORGOT_PASSWORD_API_ERROR:
      return {
        ...state,
        isForgotPasswordPending: false,
        isForgotPasswordSuccess: false,
        isForgotPasswordError: true,
        forgotPasswordError: action.payload,
        [persistKey]: nowTime,
      };
    case AGENT_FORGOT_PASSWORD_API_REQUEST:
      return {
        ...state,
        isAgentForgotPasswordPending: true,
        isAgentForgotPasswordSuccess: false,
        isAgentForgotPasswordError: false,
        [persistKey]: nowTime,
      };
    case AGENT_FORGOT_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isAgentForgotPasswordPending: false,
        isAgentForgotPasswordSuccess: true,
        isAgentForgotPasswordError: false,
        agentForgotPasswordResponse: action.payload,
        [persistKey]: nowTime,
      };
    case AGENT_FORGOT_PASSWORD_API_ERROR:
      return {
        ...state,
        isAgentForgotPasswordPending: false,
        isAgentForgotPasswordSuccess: false,
        isAgentForgotPasswordError: true,
        forgotPasswordError: action.payload,
        [persistKey]: nowTime,
      };

    case SIGNIN_VIA_FACEBOOK_API_REQUEST:
      return {
        ...state,
        isSignInViaFacebookPending: true,
        isSignInViaFacebookSuccess: false,
        isSignInViaFacebookError: false,
        [persistKey]: nowTime,
      };
    case SIGNIN_VIA_FACEBOOK_API_SUCCESS:
      return {
        ...state,
        isSignInViaFacebookPending: false,
        isSignInViaFacebookSuccess: true,
        isSignInViaFacebookError: false,
        signInViaFacebookResponse: action.payload,
        [persistKey]: nowTime,
      };
    case SIGNIN_VIA_FACEBOOK_API_ERROR:
      return {
        ...state,
        isSignInViaFacebookPending: false,
        isSignInViaFacebookSuccess: false,
        isSignInViaFacebookError: true,
        signInViaFacebookError: action.payload,
        [persistKey]: nowTime,
      };

    case GOOGLE_REDIRECT_API_REQUEST:
      return {
        ...state,
        isGoogleRedirectPending: true,
        isGoogleRedirectSuccess: false,
        isGoogleRedirectError: false,
        [persistKey]: nowTime,
      };
    case GOOGLE_REDIRECT_API_SUCCESS: {
      return {
        ...state,
        isGoogleRedirectPending: false,
        isGoogleRedirectSuccess: true,
        isGoogleRedirectError: false,
        googleRedirectResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case GOOGLE_REDIRECT_API_ERROR:
      return {
        ...state,
        isGoogleRedirectPending: false,
        isGoogleRedirectSuccess: false,
        isGoogleRedirectError: true,
        googleRedirectError: action.payload,
        [persistKey]: nowTime,
      };

    case SIGNIN_VIA_GOOGLE_API_REQUEST:
      return {
        ...state,
        isSignInViaGooglePending: true,
        isSignInViaGoogleSuccess: false,
        isSignInViaGoogleError: false,
        [persistKey]: nowTime,
      };
    case SIGNIN_VIA_GOOGLE_API_SUCCESS:
      return {
        ...state,
        isSignInViaGooglePending: false,
        isSignInViaGoogleSuccess: true,
        isSignInViaGoogleError: false,
        signInViaGoogleResponse: action.payload,
        [persistKey]: nowTime,
      };
    case SIGNIN_VIA_GOOGLE_API_ERROR:
      return {
        ...state,
        isSignInViaGooglePending: false,
        isSignInViaGoogleSuccess: false,
        isSignInViaGoogleError: true,
        signInViaGoogleError: action.payload,
        [persistKey]: nowTime,
      };

    case SET_PASSWORD_API_REQUEST:
      return {
        ...state,
        isSetPasswordPending: true,
        isSetPasswordSuccess: false,
        isSetPasswordError: false,
        [persistKey]: nowTime,
      };
    case SET_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isSetPasswordPending: false,
        isSetPasswordSuccess: true,
        isSetPasswordError: false,
        setPasswordResponse: action.payload,
        [persistKey]: nowTime,
      };
    case SET_PASSWORD_API_ERROR:
      return {
        ...state,
        isSetPasswordPending: false,
        isSetPasswordSuccess: false,
        isSetPasswordError: true,
        setPasswordError: action.payload,
        [persistKey]: nowTime,
      };

    case RESET_PASSWORD_API_REQUEST:
      return {
        ...state,
        isResetPasswordPending: true,
        isResetPasswordSuccess: false,
        isResetPasswordError: false,
        [persistKey]: nowTime,
      };
    case RESET_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isResetPasswordPending: false,
        isResetPasswordSuccess: true,
        isResetPasswordError: false,
        resetPasswordResponse: action.payload,
        [persistKey]: nowTime,
      };
    case RESET_PASSWORD_API_ERROR:
      return {
        ...state,
        isResetPasswordPending: false,
        isResetPasswordSuccess: false,
        isResetPasswordError: true,
        resetPasswordError: action.payload,
        [persistKey]: nowTime,
      };
    case AGENT_RESET_PASSWORD_API_REQUEST:
      return {
        ...state,
        isAgentResetPasswordPending: true,
        isAgentResetPasswordSuccess: false,
        isAgentResetPasswordError: false,
        [persistKey]: nowTime,
      };
    case AGENT_RESET_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isAgentResetPasswordPending: false,
        isAgentResetPasswordSuccess: true,
        isAgentResetPasswordError: false,
        agentResetPasswordResponse: action.payload,
        [persistKey]: nowTime,
      };
    case AGENT_RESET_PASSWORD_API_ERROR:
      return {
        ...state,
        isAgentResetPasswordPending: false,
        isAgentResetPasswordSuccess: false,
        isAgentResetPasswordError: true,
        agentResetPasswordError: action.payload,
        [persistKey]: nowTime,
      };

    case SIGN_OUT_API_REQUEST:
      return {
        ...state,
        isSignOutPending: true,
        isSignOutSuccess: false,
        isSignOutError: false,
        [persistKey]: nowTime,
      };
    case SIGN_OUT_API_SUCCESS: {
      const user = null;
      const params = action?.params;
      const signOutType = params?.signOutType;
      removeAccessToken();

      return {
        ...state,
        isSignOutPending: false,
        isSignOutSuccess: true,
        isSignOutError: false,
        signOutResponse: action.payload,
        user,
        encryptKey: initialState?.encryptKey,
        isAuthenticated: false,
        fatumSessionId: null,
        revalidate: false,
        [persistKey]: nowTime,
        signOutType,
      };
    }
    case SIGN_OUT_API_ERROR:
      return {
        ...state,
        isSignOutPending: false,
        isSignOutSuccess: false,
        isSignOutError: true,
        signOutError: action.payload,
        [persistKey]: nowTime,
      };

    case FORGOT_VERIFY_IDENTITY_SEND_API_REQUEST:
      return {
        ...state,
        isForgotVerifyIdentitySendPending: true,
        isForgotVerifyIdentitySendSuccess: false,
        isForgotVerifyIdentitySendError: false,
        [persistKey]: nowTime,
      };
    case FORGOT_VERIFY_IDENTITY_SEND_API_SUCCESS:
      return {
        ...state,
        isForgotVerifyIdentitySendPending: false,
        isForgotVerifyIdentitySendSuccess: true,
        isForgotVerifyIdentitySendError: false,
        forgotVerifyIdentitySendResponse: action.payload,
        forgotPasswordResponse: { ...state.forgotPasswordResponse, ...action.payload },
        [persistKey]: nowTime,
      };
    case FORGOT_VERIFY_IDENTITY_SEND_API_ERROR:
      return {
        ...state,
        isForgotVerifyIdentitySendPending: false,
        isForgotVerifyIdentitySendSuccess: false,
        isForgotVerifyIdentitySendError: true,
        forgotVerifyIdentitySendError: action.payload,
        forgotPasswordError: { ...state.forgotPasswordError, ...action.payload },
        [persistKey]: nowTime,
      };
    case CONFIRM_POLICY_API_SUCCESS: {
      const response = action?.payload;
      // // console.log('response: ', response);
      const newCredentials = response?.payload || {};
      const newCustomerDetails = newCredentials?.customerDetails[0];
      newCustomerDetails.dob = dateToString(isoStringToDob(newCustomerDetails?.dob));
      const newCustomer = {
        id: newCredentials?.customerId,
        verified: newCredentials?.customerVerified,
      };
      const customerDetails = { customer: newCustomerDetails };
      const user = {
        ...state.user,
        customer: newCustomer,
        customerDetails,
      };
      // // console.log('newUser: ', newUser);
      // newUser.id = newUser?._id || newUser?.id || state?.user?.id;
      // const mergedUser = { ...state.user, ...newUser };
      return {
        ...state,
        user,
        [persistKey]: nowTime,
      };
    }

    case VERIFY_CONTACT_API_SUCCESS: {
      const response = action.payload;
      // // console.log('response: ', response);

      return {
        ...state,
        [persistKey]: nowTime,
      };
    }

    case RESET_UNAUTH_CHANGE_PASSWORD_SUCCESS: {
      const newState = { ...state, verifyIdentityConfirmResponse: null };
      return {
        ...newState,
        [persistKey]: nowTime,
      };
    }

    case RESET_AUTH_SUCCESS: {
      return {
        ...state,
        [persistKey]: nowTime,
      };
    }

    case RESET_AUTH_STATE_SUCCESS: {
      return {
        ...initialState,
        [persistKey]: nowTime,
      };
    }

    case RESET_EXPIRED_SUCCESS: {
      return {
        ...state,
        isExpired: false,
        [persistKey]: nowTime,
      };
    }
    case CHANGE_CUSTOMER_ID_API_SUCCESS: {
      return {
        ...state,
        usertype: action?.payload?.customerType,
        [persistKey]: nowTime,
      };
    }
    case SET_AUTH_USERNAME_SUCCESS: {
      return {
        ...state,
        username: action?.payload,
        setAuthOptions: action?.options,
        [persistKey]: nowTime,
      };
    }
    case SELECT_CLIENTS_SUCCESS: {
      const selectedClient = action?.payload;
      return {
        ...state,
        selectedClient,
        [persistKey]: nowTime,
      };
    }
    case SELECT_PRODUCTS_SUCCESS: {
      const selectedProduct = action?.payload;
      return {
        ...state,
        selectedProduct,
      };
    }
    case UPDATE_SOURCE_ID_SUCCESS: {
      const data = action?.payload;
      const customer = state?.user?.customerDetails?.customer || {};
      const oldSourceArrayIds = customer?.sourceArrayIds || [];
      const newSourceArrayIds = addItemOnce(oldSourceArrayIds, data, 'system');
      customer.sourceArrayIds = newSourceArrayIds;
      const customerDetails = { ...state?.user?.customerDetails, customer };
      const user = {
        ...state.user,
        customerDetails,
      };

      return {
        ...state,
        user,
        [persistKey]: nowTime,
      };
    }
    case ADD_TRUSTED_DEVICE_API_REQUEST:
      return {
        ...state,
        isAddTrustedDevicePending: true,
        isAddTrustedDeviceSuccess: false,
        isAddTrustedDeviceError: false,
        [persistKey]: nowTime,
      };
    case ADD_TRUSTED_DEVICE_API_SUCCESS: {
      return {
        ...state,
        isAddTrustedDevicePending: false,
        isAddTrustedDeviceSuccess: true,
        isAddTrustedDeviceError: false,
        addTrustedDeviceResponse: action.payload,
        [persistKey]: nowTime,
      };
    }
    case ADD_TRUSTED_DEVICE_API_ERROR:
      return {
        ...state,
        isAddTrustedDevicePending: false,
        isAddTrustedDeviceSuccess: false,
        isAddTrustedDeviceError: true,
        [persistKey]: nowTime,
        // signUpBioError: action.payload,
      };

    case SET_RECAPTCHA_TOKEN: {
      return {
        ...state,
        recaptchaToken: action?.payload,
      };
    }
    case TRIGGER_RECAPTCHA: {
      return {
        ...state,
        triggerRecaptcha: action?.payload,
      };
    }

    case GET_FATUM_SESSION_ID_API_REQUEST:
      return {
        ...state,
        isFatumSessionIdPending: true,
        isFatumSessionIdSuccess: false,
        isFatumSessionIdError: false,
        [persistKey]: nowTime,
      };

    case GET_FATUM_SESSION_ID_API_SUCCESS: {
      return {
        ...state,
        isFatumSessionIdPending: false,
        isFatumSessionIdSuccess: true,
        isFatumSessionIdError: false,
        fatumSessionResponse: action?.payload,
        fatumSessionId: action?.payload?.payload?.sessionId,
        [persistKey]: nowTime,
      };
    }
    case GET_FATUM_SESSION_ID_API_ERROR: {
      return {
        ...state,
        isFatumSessionIdPending: false,
        isFatumSessionIdSuccess: false,
        isFatumSessionIdError: true,
        fatumSessionError: action?.payload,
        [persistKey]: nowTime,
      };
    }

    default:
      return state;
  }
}
export default authReducer;

/*
      const accessToken = user?.accessToken;
      setAccessToken(accessToken);
import { setAccessToken, removeAccessToken } from '../../storage/accessToken';
*/
