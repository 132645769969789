import { routerPostCall } from '../../axios';

export const sendQuoteDetailsURL = `/accounts/quotes/send-quote-mail-to-client`;

const sendQuoteDetails = (state, params) => {
    const url = sendQuoteDetailsURL;
    return routerPostCall(state, url, params);
};

export default { sendQuoteDetails };
