const darkTheme = {
  template: 'dutch',
  palette: {
    mode: 'dark',
    primary: {
      main: '#faa83c',
      gradient: 'linear-gradient(to bottom right, #9C0059, #DF4084)',
      contrastText: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
      gradient: 'linear-gradient(to right, #E86D1F, #FFD200)',
      contrastText: '#000000',
    },
    tertiary: {
      main: '#89aac9',
      gradient: 'linear-gradient(to right, #C60651, #F38B00)',
      contrastText: '#000000',
    },
    quaternary: {
      main: '#d894af',
      contrastText: '#000000',
      gradient: 'linear-gradient(to bottom, #181616 160vh,#201f1d 160vh)',
    },
    quinary: {
      main: '#1792e0',
      contrastText: '#000000',
    },
    senary: {
      main: '#C60651',
      contrastText: '#000000',
    },
    septenary: {
      main: '#1593de',
      contrastText: '#000000',
    },
    octonary: {
      main: '#0767e2',
      contrastText: '#000000',
    },
    nanory: {
      main: '#A94303',
      secondary: '#0F0F0F',
      gradient: 'linear-gradient(109.19deg, #E86D1F 18.87%, #FFD200 99.07%)',
    },
    dark: {
      main: '#b0ab98',
      contrastText: '#000000',
    },
    light: {
      main: '#000000',
      secondary: '#000000',
      tertiary: '#F5F5F5',
    },
    background: {
      default: '#202020', // '#282C35', // '#0d0c0a',
      paper: '#232323', // '#282C35', #262626
      grid: 'rgba(255, 255, 255, 0.23)', // '#335C67', // '#332A86', // '#124E78', // '#282C35',
      primaryBanner: '#0F0F0F',
      banner: '#404041', // '#335C67', // grey[700],
      text: '#202020',
      main: '#0F0F0F',
      chip: '#b0ab98', // 4F5467
      transparent: '#00000022',
      contentBox: '#53555b',
      fatumContentBox: '#232323',
      primaryLight: '#181616',
      chatbotDefault: 'rgba(0, 0, 0, 0.38)',
      switch: '#0F0F0F',
      container: '#000000',
    },
    text: {
      primary: '#E9EBEF',
      secondary: '#d8dae3',
      disabled: 'rgba(255,255,255,0.70)',
      label: '#d8dae3',
      main: '#FFFFFF',
      dark: '#cdced4',
      grey: '#666666',
      bright: '#303030',
      chatbotPrimary: 'rgba(0, 0, 0, 0.87)',
      chatbotSecondary: 'rgba(0, 0, 0, 0.54)',
    },
    inactive: {
      veryLight: '#ffffff',
      light: '#CED1D6',
      main: '#AEB1BD',
      dark: '#5C656D',
      veryDark: '#4F5467',
    },
    error: {
      light: '#f44336', // '#ad0505', // '#f44336',
      main: '#AA0000',
      background: 'transparent', // '#EEAABB',
    },
    warning: {
      light: '#FFDDCC', // '#FFD200', // '#F7CD86',
      main: '#DD3311',
      background: 'transparent', //
    },
    success: {
      light: '#00C5A1', // '#332A86', // '#2E30C1', // '#35AF28', // '#B1FC9C',
      main: '#00BB55',
      background: 'transparent', // '#BBEEDD',
      secondary: '#37B03C',
    },
    info: {
      light: '#0089D6',
      main: '#006CBB',
      background: 'transparent', // '#BBCCEE',
    },
    neutral: {
      light: '#E9EBEF',
      main: '#4F5467',
      background: 'transparent', // '#E9EBEF',
    },
  },
  shape: {
    borderRadius: 6,
    borderWidth: 1,
  },
  border: {
    main: '#332A86',
    light: '#D6D4E7',
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Interval Sans Pro', ' sans-serif'].join(','),
  },
  grid: {
    fontFamily: ['Interval Sans Pro', ' sans-serif'].join(','),
  },
  effects: {
    boxShadow: '0 1px 4px #0000001f !important',
    textShadow: '0 1px 1px #0000001f',
    transition: 'all .25s cubic-bezier(0.4, 0, 0.2, 1) !important',
    featureCardShadow: '-4px 4px 20px 4px #B2146740',
  },
};

export default darkTheme;
