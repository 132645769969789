const lightTheme = {
  template: 'dutch',
  palette: {
    mode: 'light',
    primary: {
      main: '#31006F', // #332A86
      gradient: 'linear-gradient(to bottom right, #9C0059, #DF4084)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#9C0059',
      gradient: 'linear-gradient(to right, #E86D1F, #FFD200)',
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#F8971D',
      gradient: 'linear-gradient(to right, #C60651, #F38B00)',
      contrastText: '#ffffff',
    },
    quaternary: {
      main: '#FFD200',
      contrastText: '#ffffff',
      gradient: 'linear-gradient(to bottom, #EAE8F3 160vh, #FFFFFF 160vh)',
    },
    quinary: {
      main: '#C60651',
      contrastText: '#ffffff',
      secondary: '#c606511a',
      gradient: 'linear-gradient(104.08deg, #9C0059 16.42%, #DF4084 93.1%)',
    },
    senary: {
      main: '#E86D1F',
      contrastText: '#000000',
    },
    septenary: {
      main: '#FFE512',
      contrastText: '#ffffff',
    },
    octonary: {
      main: '#FFCE00',
      contrastText: '#ffffff',
    },
    nanory: {
      main: '#A94303',
      secondary: '#e86d1f0d',
      gradient: 'linear-gradient(109.19deg, #E86D1F 18.87%, #FFD200 99.07%)',
    },
    denary: {
      main: 'linear-gradient(90deg, #C8114F 0%, #F1800B 100%)',
      contrastText: '#ffffff',
    },
    undecary: {
      main: '#31006E',
      contrastText: '#ffffff',
    },
    duodecary: {
      main: 'linear-gradient(109.19deg, #E86D1F 18.87%, #FFD200 99.07%)',
      contrastText: '#ffffff',
    },
    tredecary: {
      main: 'linear-gradient(104.08deg, #9C0059 16.42%, #DF4084 93.1%)',
      contrastText: '#ffffff',
    },
    quattuordecary: {
      main: 'linear-gradient(109.19deg, #E86D1F 18.87%, #FFD200 99.07%)',
      contrastText: '#ffffff',
    },
    light: {
      main: '#FFFFFF',
      secondary: '#F9F9F9',
      tertiary: '#F5F5F5',
    },
    dark: {
      main: '#4F5467',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff', // '#f3fcf0', // '#F0F2F5' //'#f3fcf0'
      paper: '#ffffff', // '#D7D6E6', // '#f3fcf0', // '#F0F2F5', // '#f5f5f5', //'#f3fcf0'
      grid: 'rgba(0, 0, 0, 0.23)', // '#f7fff7', // '#ECEDEC', // '#E4E6EB', // '#ECEDEC',
      primaryBanner: '#efeeeb',
      banner: '#E5E5E5', // grey.A100, // grey[500]
      text: '#ffffff',
      main: '#F2F2F2', // '#F0F0F0',
      chip: '#4F5467', // 4F5467
      transparent: '#FFFFFF26',
      contentBox: '#D3D2E3',
      fatumContentBox: '#eae9f3',
      primaryLight: '#F9F8FF', // '#EAE8F3',
      chatbotDefault: 'rgba(0, 0, 0, 0.38)',
      tableLabel: '#D7D6E6',
      switch: '#e5e7eb',
      container: '#EAE8F3',
      home: '#F2F2F3',
    },
    text: {
      primary: '#000000',
      secondary: '#4F5467', // '#FFFFFF',
      disabled: '#95979E', // '#95979E',
      label: '#4F5467',
      main: '#31006F', // '#332A86',
      dark: '#4F5467',
      grey: '#666666',
      bright: '#FFFFFF',
      chatbotPrimary: 'rgba(0, 0, 0, 0.87)',
      chatbotSecondary: 'rgba(0, 0, 0, 0.54)',
    },
    inactive: {
      veryLight: '#ffffff',
      light: '#CED1D6',
      main: '#AEB1BD',
      dark: '#5C656D',
      veryDark: '#4F5467',
    },
    error: {
      light: '#f44336', // '#ad0505', // '#f44336',
      main: '#AA0000',
      background: 'transparent', // '#EEAABB',
    },
    warning: {
      light: '#FFDDCC', // '#FFD200', // '#F7CD86',
      main: '#DD3311',
      background: 'transparent', //
    },
    success: {
      light: '#4caf50', // '#00C5A1',// '#332A86', // '#2E30C1', // '#35AF28', // '#B1FC9C',
      main: '#00BB55',
      secondary: '#37B03C',
      background: 'transparent', // '#BBEEDD',
    },
    info: {
      light: '#0089D6',
      main: '#006CBB',
      background: 'transparent', // '#BBCCEE',
    },
    neutral: {
      light: '#E9EBEF',
      main: '#4F5467',
      background: 'transparent', // '#E9EBEF',
    },
  },
  shape: {
    borderRadius: 6,
    borderWidth: 1,
  },
  border: {
    main: '#332A86',
    light: '#D6D4E7',
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Interval Sans Pro', ' sans-serif'].join(','),
  },
  grid: {
    fontFamily: ['Interval Sans Pro', ' sans-serif'].join(','),
  },
  effects: {
    boxShadow: '0 1px 4px #0000001f !important',
    textShadow: '0 1px 1px #0000001f',
    transition: 'all .25s cubic-bezier(0.4, 0, 0.2, 1) !important',
    featureCardShadow: '-4px 4px 20px 4px #B2146740',
  },
};

export default lightTheme;
