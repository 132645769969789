/* eslint-disable no-nested-ternary */
import queryString from 'query-string';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { filterCurrencies } from './data';
import { fuseOptions, trimOneChar } from './functions';
import { addItemOnce, isoStringToDate, stringToDate, keySort } from '../utils/functions';
import {
  searchKey,
  domain,
  investmentLob,
  agentType,
  insuranceIndexName,
  dutchTemplate,
} from '../utils/variables';

export const portfolioInsuranceOptions = ({
  state,
  staticLob,
  infiniteScroll = false,
  isMobile,
  config,
  router,
}) => {
  const { location } = router || state.router;
  const lobCategories = state?.locations?.lobCategories;
  const { cacheFilterParams } = state?.insurance;
  const alpha2 = state?.locations?.yourLocation?.alpha2;
  const storeKey = `${staticLob}_${alpha2}`;
  // const lobCategories = [...lobTypes];
  const { nextStartIndex, nextItemCount, nextScrollCount } = state.insurance;
  const isAgent = state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType;
  // console.log(lobCategories);
  const customerLobTypes = lobCategories.filter(x => x.isAvailableForCustomerPortfolio);
  const agentLobTypes = lobCategories.filter(x => x.isAvailableForAgentPortfolio);
  const newLobTypes = isAgent ? agentLobTypes : customerLobTypes;

  // const insurances = state?.insurance?.insurances || [];
  /*
  insurances.filter(function(item, index) {
    for (const key in lobCategories) {
      if (
        item.lob != undefined &&
        item.lob == lobCategories[key].lob &&
        filterLobTypes.filter(x => x.lob == item.lob).length == 0
      ) {
        filterLobTypes.push(lobCategories[key]);
      }
    }
  });
  */

  /*
  const newLobTypes = addItemOnce(
    filterLobTypes,
    {
      id: 'all',
      slug: 'all',
      lob: 'all',
      title: 'All',
    },
    'slug',
  );
  */

  const parsed = queryString.parse(location?.search); // location?.query; //
  // console.log('location: ', location);
  // console.log('location: ', location);
  // const { parsed, history } = updateQueryHistory(historyList, location);
  // historyList = [...history];
  // console.log('parsed1: ', parsed);

  const lobArray = Array.isArray(parsed?.lob)
    ? parsed?.lob
    : typeof parsed?.lob === 'string' && parsed?.lob.length > 0
    ? parsed?.lob.split(',')
    : null;

  const currencyArray = Array.isArray(parsed?.currency)
    ? parsed?.currency
    : typeof parsed?.currency === 'string' && parsed?.currency.length > 0
    ? parsed?.currency.split(',')
    : null;

  const filterInsuranceStatusArray = Array.isArray(parsed?.status)
    ? parsed?.status
    : typeof parsed?.status === 'string' && parsed?.status.length > 0
    ? parsed?.status.split(',')
    : null;

  const defaultDateStart = null; // new Date(2010, 0, 1);
  const defaultDateEnd = null; // new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  let itemsPerPageOptions = [2, 5, 10, 20, 40, 50];
  let initialCount = itemsPerPageOptions[0];

  if (isAgent) {
    initialCount = itemsPerPageOptions[1];
  }
  // if (isMobile) {
  //  initialCount = 4;
  // }
  const templateName = state?.locations?.yourLocation?.variables?.templateName;

  if (templateName === dutchTemplate) {
    itemsPerPageOptions = [3, 6, 12, 24, 48, 60];
    initialCount = itemsPerPageOptions[0];
  }

  const defaultItemCount = initialCount;

  let startIndex =
    infiniteScroll && nextStartIndex
      ? nextStartIndex
      : parseInt(parsed?.startIndex, 10) || 0;
  let itemCount =
    infiniteScroll && nextItemCount
      ? nextItemCount
      : parseInt(parsed?.itemCount, 10) || initialCount;
  let scrollCount =
    infiniteScroll && nextScrollCount
      ? nextScrollCount
      : parseInt(parsed?.scrollCount, 10) || initialCount;

  const finalScrollKey = parsed?.scrollKey; // parsed?.search?.length > 0 ? searchKey : parsed?.scrollKey; // infiniteScroll ? scrollKey :

  scrollCount = scrollCount || cacheFilterParams?.[storeKey]?.scrollCount || scrollCount;

  startIndex =
    staticLob === finalScrollKey
      ? startIndex
      : cacheFilterParams?.[storeKey]?.startIndex || 0;
  itemCount =
    staticLob === finalScrollKey
      ? itemCount
      : cacheFilterParams?.[storeKey]?.itemCount || defaultItemCount;

  /*
  if (lobType !== finalScrollKey && lobType !== searchKey) {
    startIndex = 0;
    itemCount = initialCount;
    scrollCount = initialCount;
  }
  */

  // const filterStatus = filterInsuranceStatuses; // [];
  /*
  insurances.filter(function(item, index) {
    for (const key in filterInsuranceStatuses) {
      if (
        item.filterStatus != undefined &&
        item.filterStatus == filterInsuranceStatuses[key].status &&
        filterStatus.filter(x => x.status == item.filterStatus).length == 0
      ) {
        filterStatus.push(filterInsuranceStatuses[key]);
      }
    }
  });
  */

  const newFilterCurrencies = filterCurrencies; // [];
  /*
  insurances.filter(function(item, index) {
    if (
      item.currency != undefined &&
      filterCurrency.filter(x => x == item.currency).length == 0
    ) {
      filterCurrency.push(item.currency);
    }
  });
  */

  const options = {
    lob: lobArray || [],
    staticLob,
    // mode: modeArray || [],
    status: filterInsuranceStatusArray || [],
    lobTypes: newLobTypes || [],
    statuses: [],
    currencies: newFilterCurrencies || [],
    currency: currencyArray || [],
    // modes,
    search: parsed?.search || '',
    startDate: parsed?.startDate ? isoStringToDate(parsed?.startDate) : defaultDateStart,
    endDate: parsed?.endDate ? isoStringToDate(parsed?.endDate) : defaultDateEnd,
    itemsPerPageOptions,
    startIndex,
    itemCount,
    scrollCount,
    infiniteScroll,
    isMobile,
    scrollKey: finalScrollKey,
    initialCount,
    defaultItemCount,
    indexName: insuranceIndexName,
    showFilters: {
      date: !config?.hideDateFilter,
      lob: !config?.hideLobFilter,
      currency: !config?.hideCurrencyFilter,
      status: !config?.hideStatusFilter,
    },
  };
  // // console.log('options 2: ', options);

  return options;
};

export const portfolioInsuranceFilter = ({
  state,
  staticLob,
  infiniteScroll,
  isMobile,
  config,
}) => {
  // console.log('infiniteScroll: ', infiniteScroll);
  // const { location } = state.router;
  const { insurances, searchKeys, total } = state?.insurance;
  const { searchKeys: accountSearchKeys } = state?.investments;
  const alpha2 = state?.locations?.yourLocation?.alpha2;
  // // console.log('searchInsurances: ', searchInsurances);
  // console.log('insurances: ', insurances);
  const storeKey = `${staticLob}_${alpha2}`;
  // const parsed = queryString.parse(locatiodn.search);
  const options = portfolioInsuranceOptions({
    state,
    staticLob,
    infiniteScroll,
    isMobile,
    config,
  });
  // console.log('options: ', options);
  // console.log('staticLob: ', staticLob);
  // // console.log(options);
  // gives {lob:'Health', product:'id'}
  let filteredInsurances = insurances ? [...insurances] : [];

  if (options && options.lob && options.lob.length > 0) {
    filteredInsurances = filteredInsurances.filter(
      e =>
        !e?.filterLob ||
        (e.filterLob &&
          options.lob.some(
            option =>
              e.filterLob?.toLowerCase() === option?.toLowerCase() ||
              option?.toLowerCase() === 'all',
          )),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }
  // console.log('filteredInsurances1: ', filteredInsurances);
  // // console.log('filteredSearchInsurances: ', filteredSearchInsurances);

  if (staticLob && staticLob !== searchKey) {
    filteredInsurances = filteredInsurances.filter(
      e =>
        staticLob?.toLowerCase() === e?.filterLob?.toLowerCase() ||
        staticLob?.toLowerCase() === 'all',
    );
  }
  // console.log('filteredInsurances2: ', filteredInsurances);
  // // console.log('filteredSearchInsurances: ', filteredSearchInsurances);

  /*
  if (options.startDate && options.endDate) {
    filteredInsurances = filteredInsurances.filter(
      row =>
        !row?.filterDate ||
        (row.filterDate &&
          options.startDate <= stringToDate(row?.filterDate) &&
          options.endDate >= stringToDate(row?.filterDate)),
    );
  }
*/

  /*
  if (options.currency && options.currency.length > 0) {
    filteredInsurances = filteredInsurances.filter(
      e =>
        !e?.currency ||
        (e.currency &&
          options.currency.some(
            option => e.currency?.toLowerCase() === option?.toLowerCase(),
          )),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }
  */
  /*
 
  */
  filteredInsurances = filteredInsurances.filter(
    e => e.body.infiniteScroll === infiniteScroll,
  );
  // console.log('filteredInsurances3: ', filteredInsurances);
  filteredInsurances = filteredInsurances.filter(e => e.body.isMobile === isMobile);
  // console.log('filteredInsurances4: ', filteredInsurances);
  filteredInsurances = filteredInsurances.filter(e => e.body.alpha2 === alpha2);
  // console.log('filteredInsurances5: ', filteredInsurances);
  filteredInsurances = filteredInsurances.filter(
    e =>
      (options.search && e.body.search && e.body.search === options.search) ||
      (!options.search && !e.body.search),
  );
  // console.log('filteredInsurances6: ', filteredInsurances);
  // // console.log('filteredSearchInsurances: ', filteredSearchInsurances);

  /*
  if (options.status && options.status.length > 0) {
    filteredInsurances = filteredInsurances.filter(
      e =>
        !e?.filterStatus ||
        options?.status?.some(
          option =>
            option?.toLowerCase() === e?.filterStatus?.toLowerCase() ||
            option?.toLowerCase() === 'all',
        ),
    );

    // // console.log('searchInsurances: ', searchInsurances);
  }
*/
  // // console.log('filteredInsurances: ', filteredInsurances);
  // // console.log('filteredSearchInsurances: ', filteredSearchInsurances);

  /*
  if (options.search && options.search.length > 0 && filteredInsurances.length > 0) {
    const fuse = new Fuse(filteredInsurances, fuseOptions(searchKeys));

    let searchResults = fuse.search(`${options.search}`);
    if (!searchResults || searchResults.length === 0) {
      options.search = `*${options.search}*`;
      searchResults = fuse.search(options.search); // `"'${options.search}'"` //.replace(/ /g, '|')
      options.search = trimOneChar(options.search, '*');
    }
    filteredInsurances = searchResults.map(result => result.item);
  }
*/

  // console.log('filteredInsurances: ', filteredInsurances);
  // // console.log('filteredSearchInsurances: ', filteredSearchInsurances);
  // console.log('staticLob: ', staticLob);

  // console.log('filteredAccountRows:0 ', filteredAccountRows);
  // console.log('filteredInsurances7: ', filteredInsurances);
  // console.log('options: ', options);
  if (options && options?.infiniteScroll && staticLob !== searchKey) {
    const newStartIndex = 0;
    const newItemCount = options.scrollCount;
    // console.log('options: ', options);
    // console.log('before filteredInsurances: ', filteredInsurances);
    filteredInsurances = keySort(filteredInsurances, 'body.nextScrollCount');
    // console.log('after filteredInsurances: ', filteredInsurances);

    filteredInsurances = filteredInsurances.slice(newStartIndex, newItemCount);
  } else if (options && !options.infiniteScroll && staticLob !== searchKey) {
    // console.log('options: ', options);
    const startIndex = options?.startIndex;

    const itemCount = options?.itemCount;

    filteredInsurances = filteredInsurances.filter(el => {
      return (
        el != null && el?.body?.offset === startIndex && el?.body?.limit === itemCount
      );
    });
  }

  // console.log('filteredInsurances8: ', filteredInsurances);

  filteredInsurances = filteredInsurances.filter(el => {
    return el != null;
  });

  // console.log('staticLob: ', staticLob);
  // console.log('filteredInsurances: ', filteredInsurances);
  // // console.log('filteredSearchInsurances: ', filteredSearchInsurances);
  // // console.log('options: ', options);

  /*
  const newStartIndex = options.infiniteScroll ? 0 : options.startIndex;
  const newItemCount = options.infiniteScroll
    ? options.scrollCount
    : options.itemCount + options.startIndex;
  const finalInsurances = filteredInsurances.slice(newStartIndex, newItemCount);
  */

  return {
    insurances: filteredInsurances, // finalInsurances,
    // searchInsurances: finalSearchInsurances,
    count: (total && total[storeKey]) || 0, // filteredInsurances.length, //|| total[staticLob]
    options,
    allCount: filteredInsurances.length,
  };
};

export default {
  portfolioInsuranceOptions,
  portfolioInsuranceFilter,
};

/*

    filteredInsurances = insurances.filter(
      e =>
        !e?.filterLob ||
        options.lob.some(option => e.filterLob === option || option === 'all'),
    );

    filteredInsurances = filteredInsurances.map(insurance => {
      const filteredPolicies = insurance?.policies.filter(
        policy => lobType?.toLowerCase() === policy?.filterLob?.toLowerCase(),
      );
      if (!filteredPolicies || filteredPolicies.length === 0) {
        return null;
      }
      const newInsurance = { ...insurance, policies: filteredPolicies };
      return newInsurance;
    });

      filteredInsurances = filteredInsurances.map(insurance => {
      const filteredPolicies = insurance?.policies.filter(
        e =>
          !e?.filterStatus ||
          options?.status?.some(
            option =>
              option?.toLowerCase() === e?.filterStatus?.toLowerCase() ||
              option?.toLowerCase() === 'all',
          ),
      );
      if (!filteredPolicies || filteredPolicies.length === 0) {
        return null;
      }
      const newInsurance = { ...insurance, policies: filteredPolicies };
      return newInsurance;
    });

      filteredInsurances = filteredInsurances.filter(el => {
    return el != null;
  });

    const newSearchItemCount = options.infiniteScroll
    ? options.scrollCount
    : options.itemCount + options.startIndex;
  // // console.log('options.itemCount: ', options.itemCount);
  const finalSearchInsurances = filteredSearchInsurances.slice(
    newStartIndex,
    newSearchItemCount,
  );

     filteredInsurances = filteredInsurances.map(insurance => {
      const filteredPolicies = insurance?.policies.filter(
        row =>
          !row?.filterDate ||
          (row.filterDate &&
            options.startDate <= stringToDate(row?.filterDate) &&
            options.endDate >= stringToDate(row?.filterDate)),
      );
      if (!filteredPolicies || filteredPolicies.length === 0) {
        return null;
      }
      const newInsurance = { ...insurance, policies: filteredPolicies };
      return newInsurance;
    });

*/

// return options.mode.some(option => {

//     return e?.policyData.some(policy => {
//       return policy.mode === option;
//     });
//   })

/*
  if (options.mode && options.mode.length > 0) {
    filteredInsurances = filteredInsurances.map(insurance => {
      const filteredPolicies = insurance.policies.filter(policy =>
        options.mode.some(
          option => option?.toLowerCase() === policy?.mode?.toLowerCase(),
        ),
      );
      if (!filteredPolicies || filteredPolicies.length === 0) {
        return null;
      }
      const newInsurance = { ...insurance, policies: filteredPolicies };
      return newInsurance;
    });

    searchInsurances = searchInsurances.filter(e =>
      options.mode.some(option => option?.toLowerCase() === e?.mode?.toLowerCase()),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }

  */
