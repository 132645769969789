const styles = theme => ({
  toastr: {
    // cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '320px !important',
    },
    [theme.breakpoints.up('sm')]: {
      width: '420px !important',
    },
    [theme.breakpoints.up('lg')]: {
      width: '420px !important',
    },
  },
  toastrSM: {
    // cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '320px !important',
    },
    [theme.breakpoints.up('sm')]: {
      width: '380px !important',
    },
    [theme.breakpoints.up('lg')]: {
      width: '420px !important',
    },
  },
});

export default styles;
