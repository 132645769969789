import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  useMediaQuery,
  Typography,
  Popover,
  Popper,
  Paper,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link } from 'react-router-dom';
import { useSmall } from '../../../hooks';
import { dutchTemplate } from '../../../utils/variables';

const useStyles = makeStyles(theme => {
  const isDutch = theme?.template === dutchTemplate;
  return {
    customTooltip: {
      // I used the rgba color for the standard "secondary" color
      backgroundColor: isDutch
        ? theme.palette.primary.main
        : theme.palette.background.banner,
      color: isDutch ? theme.palette.primary.main : theme.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
      borderColor: theme.palette.text.primary,
      borderWidth: theme.shape.borderWidth,
      borderStyle: 'solid',
    },
    wrapper: {
      maxWidth: '40rem',
    },
    title: {
      wordWrap: 'break-word',
    },
    popoverTooltip: ({ width }) => ({
      // I used the rgba color for the standard "secondary" color
      backgroundColor: isDutch
        ? theme.palette.primary.main
        : theme.palette.background.banner,
      color: isDutch ? theme.palette.primary.contrastText : theme.palette.text.primary,
      fontSize: theme.typography.body2.fontSize,
      borderColor: theme.palette.text.primary,
      borderWidth: theme.shape.borderWidth,
      borderStyle: 'solid',
      [theme.breakpoints.up('md')]: {
        minWidth: '10rem',
        maxWidth: '40rem',
      },
      minWidth: '10rem',
      maxWidth: '40rem',
      width: width || 'auto',
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('md')]: {
        top: '10px !important',
        left: '0px !important',
      },
    }),
    customArrow: {
      color: theme.palette.secondary.main,
    },
  };
});

const CustomTooltip = ({ title, url = '', style = {}, icon, children, width }) => {
  const classes = useStyles({ width });
  const isSmall = useSmall();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (isSmall) {
    return (
      <span style={style}>
        {(children && (
          <span aria-hidden="true" onKeyDown={handleClick} onClick={handleClick}>
            {children}
          </span>
        )) || (
          <IconButton aria-label="help" size="small" onClick={handleClick}>
            {icon || <HelpOutlineIcon />}
          </IconButton>
        )}

        {title && (
          <Popover
            id={id}
            classes={{ paper: classes.popoverTooltip, root: classes.wrapper }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ zIndex: 1000 }}
            disablePortal
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Typography align="left">{title}</Typography>
            <>{url && <Link to={url}>Learn more</Link>}</>
          </Popover>
        )}
      </span>
    );
  }
  return (
    <span onMouseEnter={handleClick} onMouseLeave={handleClose} style={style}>
      {children || (
        <IconButton aria-label="help" size="small">
          {icon || <HelpOutlineIcon />}
        </IconButton>
      )}
      {title && (
        <Popper
          style={{ zIndex: 1000 }}
          disablePortal
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          className={classes.wrapper}
        >
          <Paper className={classes.popoverTooltip}>
            <Typography align="left">{title}</Typography>
            <>{url && <Link to={url}>Learn more</Link>}</>
          </Paper>
        </Popper>
      )}
    </span>
  );
};

export default CustomTooltip;
