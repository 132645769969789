import { searchOptionTypes } from './index';

const holdingSearchCategory = {
  title: 'My Securities',
  icon: 'stacked_line_chart',
  placeholder: 'Search your Securities here...',
  value: searchOptionTypes?.holdings,
  searchKey: 'search',
  displayType: 'redirect',
  redirectUrl: 'self',
};

export const notAuthSearchCategories = [
  {
    title: 'Resources',
    icon: 'widgets',
    placeholder: 'Search Resources eg. Products, Forms and more...',
    value: searchOptionTypes?.resources,
    sendtoGA: true,
    apiCalls: [
      {
        domain: 'strapi',
        url: '/c-360-wizard-listings',
        resultsHeading: 'Online Forms',
        params: [
          {
            key: '_q',
            value: 'searchTextValue',
          },
          {
            key: 'isSearchable',
            value: true,
          },
          {
            key: 'territories.alpha2',
            value: 'locations.yourLocation.alpha2',
          },
        ],
        resultsTitleKey: 'title',
        resultsDescriptionKey: 'description',
        resultsLinkKey: '/wizards/{{slug}}',
        resultsLinkTarget: '_self',
      },
      {
        domain: 'strapi',
        url: '/gg-products',
        resultsHeading: 'Products',
        params: [
          {
            key: '_q',
            value: 'searchTextValue',
          },
          {
            key: 'territories.alpha2',
            value: 'locations.yourLocation.alpha2',
          },
        ],
        resultsTitleKey: 'title',
        resultsDescriptionKey: 'description',
        resultsLinkKey: '/products/{{slug}}',
        resultsLinkTarget: '_self',
      },

      {
        domain: 'strapi',
        url: '/gg-downloads',
        resultsHeading: 'PDF Downloads',
        params: [
          {
            key: '_q',
            value: 'searchTextValue',
          },
          {
            key: 'territories.alpha2',
            value: 'locations.yourLocation.alpha2',
          },
        ],
        resultsTitleKey: 'title',
        resultsDescriptionKey: 'description',
        resultsLinkKey: '{{file.url}}',
        resultsLinkTarget: '_blank',
      },
    ],
    displayType: 'dropdown',
  },
  {
    title: 'Online Forms',
    icon: 'integration_instructions',
    placeholder: 'Search for Online Forms here...',
    value: searchOptionTypes?.onlineForms,
    sendtoGA: true,
    apiCalls: [
      {
        domain: 'strapi',
        url: '/c-360-wizard-listings',
        resultsHeading: 'Online Forms',
        params: [
          {
            key: '_q',
            value: 'searchTextValue',
          },
          {
            key: 'isSearchable',
            value: true,
          },
          {
            key: 'territories.alpha2',
            value: 'locations.yourLocation.alpha2',
          },
        ],
        resultsTitleKey: 'title',
        resultsDescriptionKey: 'description',
        resultsLinkKey: '/wizards/{{slug}}',
        resultsLinkTarget: '_self',
      },
    ],
    displayType: 'dropdown',
  },
  {
    title: 'Products',
    icon: 'shopping_basket',
    placeholder: 'Search for Products here...',
    value: searchOptionTypes?.products,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/products',
    sendtoGA: true,
  },
];

const sharedCategories = [
  {
    title: 'My Portfolio',
    icon: 'photo_camera_front',
    placeholder: 'Search for your Policies/Accounts here...',
    value: searchOptionTypes?.portfolio,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/portfolio/summary',
  },
  {
    title: 'My Claims',
    icon: 'chrome_reader_mode',
    placeholder: 'Search for your Claims here...',
    value: searchOptionTypes?.claims,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/claims',
  },
  {
    title: 'My Quotes',
    icon: 'local_atm',
    placeholder: 'Search for your Quotes here...',
    value: searchOptionTypes?.quotes,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/quotes',
  },
];

export const authSearchCategories = [
  ...sharedCategories,
  {
    title: 'My Receipts',
    icon: 'receipt',
    placeholder: 'Search for your Receipts here...',
    value: searchOptionTypes?.receipts,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/receipts',
  },
  {
    title: 'My Applications',
    icon: 'shopping_bag',
    placeholder: 'Search for your Applications here...',
    value: searchOptionTypes?.applications,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/applications',
  },
  {
    title: 'My Notifications',
    icon: 'notifications',
    placeholder: 'Search for Notifications here...',
    value: searchOptionTypes?.notifications,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/notifications',
  },
  ...notAuthSearchCategories,
];

export const agentSearchCategories = [
  ...sharedCategories,
  {
    title: 'My Clients',
    icon: 'account_circle',
    placeholder: 'Search for your Clients here...',
    value: searchOptionTypes?.clients,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/clients',
  },
  {
    title: 'My Client Policies',
    icon: 'book',
    placeholder: 'Search for your Client Policies here...',
    value: searchOptionTypes?.clientPolicies,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/client-policies',
  },
  {
    title: 'Products',
    icon: 'shopping_basket',
    placeholder: 'Search for Products here...',
    value: searchOptionTypes?.products,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/products',
  },
];

export const dutchAgentSearchCategories = [
  {
    title: 'My Clients',
    icon: 'account_circle',
    placeholder: 'Search for your Clients here...',
    value: searchOptionTypes?.clients,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/clients',
  },
  {
    title: 'My Client Policies',
    icon: 'book',
    placeholder: 'Search for your Client Policies here...',
    value: searchOptionTypes?.clientPolicies,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/client-policies',
  },
  {
    title: 'My Quotes',
    icon: 'local_atm',
    placeholder: 'Search for your Quotes here...',
    value: searchOptionTypes?.quotes,
    searchKey: 'search',
    displayType: 'redirect',
    redirectUrl: '/quotes',
  },
];

export const holdingSearchCategories = [holdingSearchCategory, ...authSearchCategories];
export default [...notAuthSearchCategories, ...holdingSearchCategories];
