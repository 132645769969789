import {
  GET_INVEST_REQUEST_SETTING_API_REQUEST,
  GET_INVEST_REQUEST_SETTING_API_SUCCESS,
  GET_INVEST_REQUEST_SETTING_API_ERROR,
} from '../../../constants/requestActionNames';

import { getInvestRequestSettingApi } from '../../../apis/requests';

function getInvestRequestSettingRequest() {
  return {
    type: GET_INVEST_REQUEST_SETTING_API_REQUEST,
    payload: true,
  };
}

function getInvestRequestSettingSuccess(data, params) {
  return {
    type: GET_INVEST_REQUEST_SETTING_API_SUCCESS,
    payload: data,
    params,
  };
}

function getInvestRequestSettingError(err) {
  return {
    type: GET_INVEST_REQUEST_SETTING_API_ERROR,
    payload: err,
  };
}

export function getInvestRequestSettingAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(getInvestRequestSettingRequest());
    return getInvestRequestSettingApi
      .getInvestRequestSetting(state, params)
      .then(data => {
        dispatch(getInvestRequestSettingSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getInvestRequestSettingError(error));
        throw error;
        // return response;
      });
  };
}

export default { getInvestRequestSettingAction };
