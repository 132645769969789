import { routerGetCall } from '../../axios';

const getInvestRequestSetting = (state, params) => {
  const url = `/cms/invest-requests-config`;
  return routerGetCall(state, url, params);
};
export default { getInvestRequestSetting };

// https://c360-core.azure-api.net/cms/getUserLocation
// https://c360-core.azure-api.net/cms/locale
