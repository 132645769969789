import { useSelector, useDispatch } from 'react-redux';
import { dateToIsoString, dateToString, toTitleCase } from '../utils/functions';
import { portfolioInsuranceSelector } from '../selectors';
import { customerType, agentType } from '../utils/variables';
import { getInsurances } from '../actions/portfolio';
import useRouter from './useRouter';

const useInsurance = ({
  isMobile = false,
  staticLob = '',
  infiniteScroll = false,
} = {}) => {
  // console.log('infiniteScroll: ', infiniteScroll);
  const dispatch = useDispatch();
  const router = useRouter();
  const user = useSelector(state => state.auth.user);
  const selectedClient = useSelector(state => state?.auth?.selectedClient);
  const usertype = useSelector(state => state.auth.usertype);
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const customerId =
    usertype === agentType ? selectedClient?.clientNumber : user?.customer?.id;
  const agentId = user?.agent?.id || '';
  const defaultOptions = useSelector(state =>
    portfolioInsuranceSelector.portfolioInsuranceOptions({
      state,
      staticLob,
      isMobile,
      infiniteScroll,
      router,
    }),
  );
  // console.log('defaultOptions 1: ', defaultOptions);
  return ({
    nextStartIndex,
    nextItemCount,
    nextScrollCount,
    actionIndex,
    infiniteScroll: primaryInfiniteScroll,
    forceReload,
    overrideStartIndex,
    isMobile: primaryIsMobile,
  } = {}) => {
    // console.log('primaryInfiniteScroll: ', primaryInfiniteScroll);
    let finalInfiniteScroll = infiniteScroll;
    if (primaryInfiniteScroll !== undefined) {
      finalInfiniteScroll = primaryInfiniteScroll;
    } else {
      finalInfiniteScroll = infiniteScroll;
    }

    let finalIsMobile = isMobile;
    if (primaryIsMobile !== undefined) {
      finalIsMobile = primaryIsMobile;
    } else {
      finalIsMobile = isMobile;
    }
    // console.log('finalIsMobile: ', finalIsMobile);
    // console.log('nextStartIndex: ', nextStartIndex);
    // console.log('nextItemCount: ', nextItemCount);
    let newStart = 0;
    let newLimit = defaultOptions?.defaultItemCount || 0;
    if (
      (staticLob &&
        defaultOptions?.scrollKey &&
        staticLob === defaultOptions?.scrollKey) ||
      finalInfiniteScroll
    ) {
      if (nextStartIndex) {
        newStart = nextStartIndex;
      } else if (defaultOptions?.startIndex) {
        newStart = defaultOptions?.startIndex;
      } else if (defaultOptions?.startIndex === 0) {
        newStart = defaultOptions?.startIndex;
      }
      if (newStart < 0) {
        newStart = 0;
      }
      if (overrideStartIndex) {
        newStart = nextStartIndex;
      }

      if (nextItemCount) {
        newLimit = nextItemCount;
      } else if (defaultOptions?.itemCount) {
        newLimit = defaultOptions?.itemCount;
      } else if (defaultOptions?.itemCount === 0) {
        newLimit = defaultOptions?.itemCount;
      }
    }

    // console.log('next load nextStartIndex: ', nextStartIndex);
    const body = {
      customerId,
      agentId: agentId || undefined,
      usertype: usertype || customerType,
      limit: newLimit,
      offset: newStart,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: defaultOptions?.lob, // lob: toTitleCase(defaultOptions?.lob?.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
      scrollKey: defaultOptions?.scrollKey,
      staticLob: defaultOptions?.staticLob,
      isMobile: finalIsMobile,
      infiniteScroll: finalInfiniteScroll,
      finalInfiniteScroll,
      alpha2,
      forceReload,
      search: defaultOptions?.search ? defaultOptions?.search : undefined,
    };

    const params = {
      customer: customerId || undefined,
      // agentId: agentId || undefined,
      // usertype: usertype || undefined,
      limit: newLimit,
      start: newStart,
      policyType: defaultOptions?.staticLob ? defaultOptions?.staticLob : undefined,
      q: defaultOptions?.search ? defaultOptions?.search : undefined,
      secondaryStatus:
        defaultOptions.status && defaultOptions.status.length > 0
          ? defaultOptions.status
          : undefined,
      startDate: defaultOptions.startDate
        ? dateToIsoString(defaultOptions.startDate)
        : undefined,
      endDate: defaultOptions.endDate
        ? dateToIsoString(defaultOptions.endDate)
        : undefined,
      currency:
        defaultOptions.currency && defaultOptions.currency.length > 0
          ? defaultOptions.currency
          : undefined,
    };

    // console.log('params: ', params);
    return dispatch(getInsurances.getInsurancesAction(params, body));
  };
};
export default useInsurance;
